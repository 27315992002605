import { checkConsentForPurpose } from "./integration/didomi";
import { getArticleIgnoreList } from "./helpers/getTeaserIgnoreList";
async function poofTeaser(elements, isWidget) {
  if (!elements?.length) return;
  const hasConsent = await new Promise(resolve => {
    setTimeout(() => resolve(false), 1000);
    checkConsentForPurpose("marketing", resolve);
  });
  [...elements].forEach(async element => {
    await buildPoofTeaser(element, isWidget, hasConsent);
  });
}
async function buildPoofTeaser(autoteaser, isWidget, hasConsent) {
  const content = document.getElementById("content");
  const teasers = content.querySelectorAll(".teaser");
  const rotatorTeasers = content.querySelectorAll(".rotator .slider__items");
  const {
    position: index,
    contentId,
    timeWindow,
    widgetUuid
  } = autoteaser.dataset;
  const articleIgnoreList = getArticleIgnoreList(teasers, autoteaser, rotatorTeasers);
  const url = new URL("/poof", window.location.origin);
  const response = await fetch(url.href, {
    credentials: "same-origin",
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      ignoreList: articleIgnoreList,
      pathname: window.location.pathname || "",
      timeWindow,
      contentId,
      hasConsent,
      index,
      widgetId: widgetUuid
    }),
    cache: "no-store"
  });
  if (response.status !== 200 && !isWidget) {
    autoteaser.classList.remove("visibility-hidden");
    return;
  }
  const personalizedContent = await response.text();
  const template = document.createElement("template");
  template.innerHTML = personalizedContent;
  autoteaser.replaceChildren(...template.content.querySelector(".teaser").children);
  autoteaser.classList.remove("visibility-hidden");
}
const poofWidgets = window.document.querySelectorAll(".poof-widget");
const autoteasers = window.document.querySelectorAll(".teaser[data-autoteaser=true]");
poofTeaser(poofWidgets, true);
poofTeaser(autoteasers, false);